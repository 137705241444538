<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="role"
        navigation="roles"
        :onNew="() => { return { roleName: null, permissions: null, description: null } }"
        title="Role">
        <template slot-scope="{ item, data }">

            <BT-Field-String
                v-model="item.roleName"
                label="Name"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-String
                v-model="item.description"
                label="Description"
                :isEditing="data.isEditing || data.isNew" />

            <v-expansion-panels v-model="panelV" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>Permissions</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="mt-2">
                            <v-col cols="4">
                                <v-btn-toggle v-model="filterToggle">
                                    <v-btn small value="All">All</v-btn>
                                    <v-btn small value="Groups">Groups</v-btn>
                                    <v-btn small value="Templates">Templates</v-btn>
                                </v-btn-toggle>
                                <v-list v-if="filterToggle == 'Groups'">
                                    <v-list-item-group
                                        v-model="selectedFilter">
                                        <template v-for="(grp, index) in groups">
                                            <v-list-item
                                                :key="index"
                                                :value="grp">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ grp }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                                <v-list v-if="filterToggle == 'Templates'">
                                    <v-list-item-group
                                        v-model="selectedTemplate">
                                        <template v-for="(template, index) in templates">
                                            <v-list-item
                                                :key="index"
                                                :value="template">
                                                <v-list-item-icon>
                                                    <v-icon v-if="templatePermitted(template, item)" small class="success--text">mdi-check</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ template.templateName }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <v-col cols="8">
                                <BT-Table
                                    :canExportCSV="false"
                                    :canRefresh="false"
                                    :canSelect="false"
                                    hideActions
                                    navigation="user-permissions"
                                    :onFilter="filterPermissions"
                                    :onPullSuccessAsync="list => { return pullPermissions(list, item) }"
                                    showList
                                    title="Permissions">
                                    <template v-slot:listItem="p">
                                        <v-list-item-action>
                                            <v-row v-if="data.isEditing || data.isNew" dense>
                                                <v-btn
                                                    :disabled="!p.item.isViewable"
                                                    small
                                                    icon
                                                    @click.stop="togglePermissionView(p.item, item)">
                                                    <v-icon
                                                        title="View"
                                                        small
                                                        :class="p.item.canView || p.item.canEdit ? 'success--text' : ''">mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    :disabled="!p.item.isEditable"
                                                    small
                                                    icon
                                                    @click.stop="togglePermissionEdit(p.item, item)">
                                                    <v-icon
                                                        title="Edit"
                                                        small
                                                        :class="p.item.canEdit ? 'success--text' : ''">mdi-pencil</v-icon>
                                                </v-btn>
                                            </v-row>
                                            <v-row v-else>
                                                <v-icon
                                                    class="mx-1"
                                                    :disabled="!p.item.isViewable"
                                                    title="View"
                                                    small
                                                    :class="p.item.canView || p.item.canEdit ? 'success--text' : ''">mdi-eye</v-icon>
                                                <v-icon
                                                    class="mx-1"
                                                    :disabled="!p.item.isEditable"
                                                    title="Edit"
                                                    small
                                                    :class="p.item.canEdit ? 'success--text' : ''">mdi-pencil</v-icon>
                                            </v-row>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            {{ p.item.text }}
                                        </v-list-item-content>
                                    </template>
                                </BT-Table>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Role-Blade',
    components: {
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            currentTemplate: null,
            filterToggle: 'Groups',
            groups: [],
            panelV: [0, 1],
            selectedFilter: null,
            selectedTemplate: null,
            templates: [
                {
                    templateName: 'Sales Operator', 
                    description: 'Process customers\' purchase orders, standing orders, and invoices.', 
                    permissions: [
                        'customers.view',
                        'customer-orders.edit',
                        'customer-invoices.edit',
                        'customer-invoice-external-parties.edit',
                        'locations.view',
                        'order-sheets.edit',
                        'standing-customer-orders.edit',
                    ]
                },
                {
                    templateName: 'Sales Manager',
                    description: 'Manager settings in relation to sales',
                    permissions: [
                        'account-links.edit',
                        'automatic-line-items.edit',
                        'customers.view',
                        'customer-orders.edit',
                        'customer-invoices.edit',
                        'customer-invoice-external-parties.edit',
                        'locations.view',
                        'ordering-functions.edit',
                        'order-invoicing-functions.edit',
                        'order-rules.edit',
                        'order-sheets.edit',
                        'order-slots.edit',
                        'order-slot-groups.edit',
                        'process-schedules.edit',
                        'product-offering-groups.edit',
                        'standing-customer-orders.edit',
                        'supplier-price-tiers.edit',
                    ]
                },
                {
                    templateName: 'User Manager',
                    description: 'Manage users and roles',
                    permissions: [
                        'roles.edit',
                        'users.edit',
                        'user-roles.edit',
                        'user-connections.edit'
                    ]
                },
                { 
                    templateName: 'Customer Manager', 
                    description: 'Manage agreements with customers, ordering schedules, etc.', 
                    permissions: [
                        'customers.edit', 
                        'customer-external-parties.edit',
                        'customer-settings.edit',
                        'customer-templates.edit', 
                        'customer-groups.edit', 
                        'customer-requests.edit', 
                        'customer-external-parties.edit', 
                        'stock-management-customers.edit', 
                        'create-customers.edit']
                },
                {
                    templateName: 'Stock Manager',
                    description: 'Manages anything to do with stock consignments and fulfilling those consignments',
                    permissions: [
                        'locations.view',
                        'products.view',
                        'product-tethers.edit',
                        'stock-adjustments.edit',
                        'stock-batches.edit',
                        'stock-increments.edit',
                        'stock-items.edit',
                        'stock-levels.edit',
                        'stock-management-settings.edit',
                        'stock-thresholds.edit',
                        'stock-tracking-settings.edit',
                        'stock-transfers.edit',
                        'stock-consignments.edit',
                        'supply-location-suggestions.view',
                        'supply-pointers.edit',
                    ]
                },
                { 
                    templateName: 'Courier Operator',
                    description: 'Request dispatch of consignments',
                    permissions: [
                        'couriers.view',
                        'courier-orders.edit',
                    ]
                },
                { 
                    templateName: 'Driver', 
                    description: 'Perform deliveries and complete journeys.', 
                    permissions: [
                        'driver.edit'
                        // 'courier-packing.edit',
                        // 'deliveries.edit',
                        // 'journeys.edit',
                        // 'locations.view',
                        // 'measurements.view',
                        // 'products.view', 
                        // 'releases.edit',
                        // 'stock-increments.view',
                        // 'stock-picking.edit',
                        // 'customer-orders.view'
                        ]
                },
                { 
                    templateName: 'Factory Operator', 
                    description: 'Create, release, and remove batches.', 
                    permissions: [
                        'locations.view', 
                        'products.view',
                        'stock-batches.edit']
                },
                { 
                    templateName: 'Picking Operator', 
                    description: 'Assign and pick stock items to fill orders', 
                    permissions: [
                        'stock-consignments.edit', 
                        'stock-items.edit']
                },
                {
                    templateName: 'Packing Operator',
                    description: 'Pack consignments for dispatch',
                    permissions: [
                        'courier-packing.edit', 
                        'measurements.view']
                },
                { 
                    templateName: 'Routing Operator', 
                    description: 'Plan routes, pick-ups, and drop-offs for journeys.', 
                    permissions: [
                        'route-suggestions.view', 
                        'movements.edit', 
                        'journeys.edit', 
                        'client-orders.edit', 
                        'journey-suggestions.view']
                },
                { 
                    templateName: 'Routing Manager', 
                    description: 'Manage route pointers, journey pointers, and journey templates.', 
                    permissions: [
                        'route-suggestions.view', 
                        'movements.edit', 
                        'journeys.edit', 
                        'client-orders.edit', 
                        'route-pointers.edit', 
                        'journey-pointers.edit', 
                        'journey-suggestions.view', 
                        'journey-templates.edit']
                },
            ],
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        templatePermitted(template, role) {
            var res = true;
            var ePerms = role.permissions != null ? role.permissions.split(',').map(x => { return {
                left: x.split('.')[0],
                right: x.split('.')[1]
            }}) : [];

            template.permissions.forEach(x => {
                var val = x.split('.')[0];

                var existingPerm = ePerms.find(ePerm => ePerm.left == val);
                if (existingPerm == null) {
                    res = false;
                }
                else {
                    if (!(existingPerm.right == 'edit' || x.split('.')[1] == 'view')) {
                        res = false;
                    }
                }
            })

            return res;
        },
        togglePermissionView(pItem, role) {
            var v = pItem.value + '.view';
            console.log(v);
            if (pItem.canEdit) {
                return;
            }

            var rList = role.permissions != null ? role.permissions.split(',') : [];
            if (pItem.canView) {
                //remove
                rList = rList.filter(y => y != null && y.length > 0 && y != v);
                role.permissions = rList.length == 0 ? null : rList.toString();
            }
            else {
                //add
                rList.push(v);
                role.permissions = rList.toString();
            }

            pItem.canView = !pItem.canView;
        },
        togglePermissionEdit(pItem, role) {
            var v = pItem.value + '.edit';

            var rList = role.permissions != null ? role.permissions.split(',') : [];
            if (pItem.canEdit) {
                //remove
                rList = rList.filter(y => y != null && y.length > 0 && y != v);
                role.permissions = rList.length == 0 ? null : rList.toString();
            }
            else {
                //add
                //remove view
                rList = rList.filter(y => y != null && y.length > 0 && y != (pItem.value + '.view'));
                console.log(rList);
                rList.push(v);
                console.log(rList);
                role.permissions = rList.toString();
            }

            pItem.canEdit = !pItem.canEdit;
        },
        filterPermissions(list) {
            if (this.filterToggle == 'All') {
                return list;
            }
            else if (this.filterToggle == 'Groups') {
                return list.filter(x => x.group == this.selectedFilter);
            }
            else if (this.selectedTemplate != null) {
                return list.filter(x => this.selectedTemplate.permissions.some(p => p.split('.')[0] == x.value));
            }
            else {
                return [];
            }
        },
        pullPermissions(permissions, role) {
            var roles = [role];
            var grpList = permissions
                .reduce((prev, curr) => {
                    if (!prev.some(z => z == curr.groupName)) {
                        prev.push(curr.groupName);
                    }

                    return prev;
                }, []);

            grpList.sort(firstBy(x => x, { ignoreCase: true }));
            this.groups = grpList;

            var allPermissions = permissions
                .reduce((prev, curr) => {
                    var split = curr.permissionValue.split('.');
                    var p = {
                        text: this.capitalizeWords(split[0].replaceAll('-', ' ')),
                        value: split[0],
                        isEditable: false,
                        isViewable: false,
                        isAble: false,
                        canEdit: false,
                        canView: false,
                        can: false,
                        group: curr.groupName
                    }

                    var existing = prev.find(y => y.value == p.value);
                    if (existing == null) {
                        existing = p;
                        prev.push(existing);
                    }

                    if (split.length > 1) {
                        //can view or can edit?
                        if (split[1] == 'edit') {
                            existing.isEditable = true;
                        }
                        else if (split[1] == 'view') {
                            existing.isViewable = true;
                        }
                    }
                    else {
                        existing.isAble = true;
                    }
                    
                    return prev;
                }, [])
                .sort(firstBy(x => x.text, { ignoreCase: true }));
            
            var rolePermissions = roles.flatMap(x => x.permissions != null ? x.permissions.split(',') : []);

            rolePermissions.forEach(rPerm => {
                var split = rPerm.split('.');

                var existingP = allPermissions.find(x => x.value == split[0]);
                if (existingP != null) {
                    if (split.length > 1) {
                        if (split[1] == 'edit') {
                            existingP.canEdit = true;
                        }
                        else if (split[1] == 'view') {
                            existingP.canView = true;
                        }
                    }
                    else {
                        existingP.can = true;
                    }
                }
            });

            return allPermissions;
        }
    }
}
</script>